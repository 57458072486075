<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12 header">
        <h1 class="text-center">Council of Frogs</h1>
        <h5 class="text-center">Make the Leap to another blockchain.</h5>
      </div>
      <div class="col-md-12">
        <div class="card">
          <div class="btn-group" role="group" aria-label="Basic example">
            <button type="button" class="btn btn-outline-primary" @click="setNetwork('bch')">
              SmartBCH
              <img src="@/assets/bitcoin-cash-bch-logo.png" width="16" height="16" />
            </button>
            <button type="button" class="btn btn-outline-primary" @click="setNetwork('avax')">
              Avalanche
              <img src="@/assets/avalanche-avax-logo.png" width="16" height="16" />
            </button>
            <button type="button" class="btn btn-outline-primary" @click="setNetwork('ftm')">
              Fantom
              <img src="@/assets/fantom-ftm-logo.png" width="16" height="16" />
            </button>
            <button type="button" class="btn btn-outline-primary" @click="setNetwork('doge')">
              DogeChain
              <img src="@/assets/dogecoin-doge-logo.png" width="16" height="16" />
            </button>
            <button type="button" class="btn btn-outline-primary" @click="setNetwork('ethw')">
              EthereumPoW
              <img src="@/assets/ethereum-eth-logo.png" width="16" height="16" />
            </button>
            <button type="button" class="btn btn-outline-primary" @click="setNetwork('shib')">
              Shibarium
              <img src="@/assets/shiba-inu-shib-logo.png" width="16" height="16" />
            </button>
            <button type="button" class="btn btn-outline-primary" @click="setNetwork('polygon')">
              Polygon
              <img src="@/assets/polygon-matic-logo.png" width="16" height="16" />
            </button>
            <button type="button" class="btn btn-outline-primary" @click="setNetwork('bnb')">
              Binance Smart Chain
              <img src="@/assets/bnb-bnb-logo.png" width="16" height="16" />
            </button>
            <!--button type="button" class="btn btn-primary" @click="setNetwork('ethereum')">Ethereum</button-->
          </div>
        </div>
        <div class="col-md-12 text-sm-end" v-if="$store.state.metamask.address">
            Connected as <strong>{{ $store.state.metamask.address.substring(0,4) }}...{{ $store.state.metamask.address.substring(38, 42) }}</strong>
        </div>
      </div>
    </div>
    <div class="row" v-if="!$store.state.metamask.connected">
      <div class="col-md-12 text-center">
        <h5 class="card-title">Connect Metamask</h5>
        <button class="metamask-detected btn btn-primary" @click="connect()">Connect me!</button>
        <p v-if="!$store.state.metamask.available">Metamask not found. Please install it!</p>
      </div>
    </div>
    <router-view/>
    <div class="row">
      <div class="col-12 text-end">
        <a href="https://shadowkite.io/" target="_blank">
          <img src="@/assets/shadowkite-black-small.png" style="margin-top: 50px;" />
        </a>
      </div>
    </div>
  </div>
</template>

<style>
  .row {
    margin: 1em 1em;
  }
</style>

<script>
import netInfo from "../config/networks.json";

export default {
  methods : {
    connect: function() {
      if(typeof window.ethereum !== 'undefined') {
        this.$store.commit('SET_METAMASKAVAILABLE', true);
        window.ethereum.request({ method: 'eth_requestAccounts' }).then(() => {
          this.$store.commit('SET_METAMASKADDRESS', window.ethereum.selectedAddress);
        });

        window.ethereum.on("accountsChanged", () => {
          window.location.reload();
        });
        window.ethereum.on("chainChanged", (chain) => {
          for(var i in netInfo.networks) {
            if(chain === netInfo.networks[i].chain) {
              this.setNetwork(netInfo.networks[i].short)
            }
          }
        });
      }
    },
    setNetwork: function(network) {
      let connectedNetwork = null;
      for(var i in netInfo.networks) {
        if(network === netInfo.networks[i].short) {
          connectedNetwork = netInfo.networks[i];
        }
      }
      this.$store.commit('SET_NETWORK', network);
      this.$store.commit('SET_NETWORKOBJECT', connectedNetwork);
    }
  },
  mounted() {
    this.connect();
  }
}
</script>

<style scoped lang="scss">
.header {
  text-shadow: -1px -1px 0 #FFF, 1px -1px 0 #FFF, -1px 1px 0 #FFF, 1px 1px 0 #FFF;
  background-image: url('~@/assets/sus.png');
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  font-family: cursive;
}
</style>